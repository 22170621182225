
const timeCounter = () => {
  // Vérifier si le compteur a débuté
  // Mettre à jour toutes les 20 secondes
}


// Récupère listeVille et userData
const prepareData = (firebase, callback) => {
  firebase.getData("liste_villes")
  .then(doc => {
    if (!doc.exists) {
      console.log('No such document!');
    } else {
      const listeVille = doc.data().villes_ready;
      firebase.getUid(function(uid){
        firebase.userData(uid)
        .then(user_doc => {
          if (!user_doc.exists) {
            console.log('No such document!');
          } else {
            const userData = user_doc.data()
            userData['uid'] = uid
            if (callback){
              callback(listeVille, userData)
            }
          }
        })
        .catch(err => {
          console.log('Error getting document', err);
        });
      })
    }
  })
  .catch(err => {
    console.log('Error getting document', err);
  });

}


// Créer le manager de tags ici

const formatAnnonce = (id, data, callback) => {
  
  const annonce = {
    id: id,
    prix: data.prix,
    surface: data.surface,
    loyer: Math.round(data.loyer/10) * 10,
    renta: Math.round(data.renta * 100) / 100,
    square_meter_price: Math.round(data.square_meter_price/10) * 10,
    lien: data.url,
    descriptif: data.descriptif,
    data: data
  }
  if (data.hasOwnProperty('photos')){
    annonce['photos'] = data.photos
  }

  if (data.descriptif !== undefined){
    annonce['descriptif'] = data.descriptif.substring(0,400) + '...'
  } else {
    annonce['descriptif'] = 'Aucune description pour ce bien'
  }

  callback(annonce)
}

function containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
        if (list[i]['id'] === obj['id']) {
            return true;
        }
    }

    return false;
}

module.exports = {formatAnnonce, prepareData, containsObject};
