import React from 'react';
import Services from './composants/Services';
import Features from './composants/Features';
import Pricing from './composants/Pricing';
import Started from './composants/Started';
import Footer from './composants/Footer';
import MobileButton from './composants/MobileButton';
import { Link } from 'react-router-dom';
import Aux from './hoc/Aux_';

import * as ROUTES from '../../constants/routes';

class Landing extends React.Component {
  constructor() {
    super();
    this.state = {
      isDesktop: window.innerWidth > 575
    };
  }
  //ça sert à éviter de crasher parce que le composant est lourd
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {

    var bkg1 = {
        backgroundImage: 'url(images/wave-shape/wave1.png)',
      };
      var bkg2 = {
        backgroundImage: 'url(images/wave-shape/wave2.png)',
      };
      var bkg3 = {
        backgroundImage: 'url(images/wave-shape/wave3.png)',
      };

  	return (
         <Aux>
                <section className="section bg-home home-half" id="home" data-image-src="images/bg-home.jpg">
                    <div className="bg-overlay"></div>
                    <div className="display-table">
                        <div className="display-table-cell">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-9 offset-lg-1 text-white text-center">
                                        <h1 className="home-title">
                                        Comparateur d’annonces pour{<br/>}
                                        investisseurs immobiliers ambitieux
                                        </h1>
                                        <p className="padding-t-10 home-desc">
                                        Vous voulez minimiser vos efforts de recherches, optimiser votre temps et recevoir les meilleures annonces en quelques secondes ? {<br/>}
                                        </p>
                                        {this.state.isDesktop &&
                                          <Link to={ROUTES.HOME} style={{zIndex: 998, color: 'white'}} className="btn btn-custom margin-t-30 waves-effect waves-light">Je commence <i className="mdi mdi-arrow-right"></i></Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="wave-effect wave-anim">
                        <div className="waves-shape shape-one">
                            <div className="wave wave-one" style={bkg1}></div>
                        </div>
                        <div className="waves-shape shape-two">
                            <div className="wave wave-two" style={bkg2}></div>
                        </div>
                        <div className="waves-shape shape-three">
                            <div className="wave wave-three" style={bkg3}></div>
                        </div>
                    </div>
               </section>

                {/* Services Component <Services /> */}
                <Services />

                {/* Features Component   <Features />  */}
                <Features isDesktop={this.state.isDesktop} />

                <Pricing />

                <Started />

                {/* Footer Component*/}
                <Footer />

                {/* FooterLinks Component    <FooterLinks /> */}
                {!this.state.isDesktop &&
                  <MobileButton isDesktop={this.state.isDesktop} />
                }

        </Aux>
  	);
  }
}



export default Landing;
