import React from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../../constants/routes';

class Features extends React.Component {
  render() {
  	return (
        <section className="section bg-light" id="features">
        <div className="container">
            <div className="row vertical-content">
                <div className="col-lg-5">
                    <div className="features-box">
                        <h3>L'outil qui accélère votre accès à l'indépendance financière</h3>
                        <p className="text-muted web-desc">
                        Il est important pour nous de <b>mettre les nouvelles technologies au service de l'investissement immobilier</b>.
                        C’est ainsi qu’est née Rentafirst, une <b>intelligence artificielle</b> aux fonctionnalités puissantes, entièrement <b>dédiée au succès des investisseurs immobiliers</b>.
                        </p>
                        <ul className="text-muted list-unstyled margin-t-10 features-item-list">
                            <li className=""><b>Recherche par rentabilité</b> ou par prix au m², </li>
                            <li className=""><b>Alertes par email</b>,</li>
                            <li className=""><b>Estimation précise des loyers praticables</b>,</li>
                            <li className="">Gestion des <b>favoris</b> et assistant de visite (bientôt disponible)...</li>
                            <li className="">Une <b>limitation de la plateforme à 100 utilisateurs</b> pour vous assurer l'exclusivité sur les meilleures affaires</li>
                        </ul>
                        <p className="text-muted web-desc">Et bien d'autres fonctionnalités surprenantes à venir...</p>
                        {this.props.isDesktop &&
                          <Link to={ROUTES.HOME} style={{zIndex: 998, color: 'white'}} className="btn btn-custom margin-t-30 waves-effect waves-light">S'inscrire<i className="mdi mdi-arrow-right"></i></Link>
                        }
                    </div>
                </div>
                {window.innerWidth > 990 &&
                  <div className="col-lg-7">
                      <div className="features-img features-right text-right">
                          <img src={require("../images/screenshot_cashflow.png")} alt="screenshot rentabilité" className="img-fluid" />
                      </div>
                  </div>
                }
            </div>
        </div>
    </section>
  	);
  }
}
export default Features;
