import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Button, Card, CardBody, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const SignUpPage = () => (
  <div>
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
  fake_email:''
};

//Pour appeler firebase renommé en bas pour utiliser le composant avec withFirebase
class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, passwordOne } = this.state;

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase
          .createUser(authUser.user.uid)
          .set({
            email: email
          });
      })
      .then(authUser => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  }

  onChange = event => {
     this.setState({ [event.target.name]: event.target.value });
     if (this.state.email.includes('yopmail')){
       this.setState({fake_email:'Veuillez entrer une adresse mail valide.'})
     } else {
       this.setState({fake_email:''})
     }
   };

  render() {
    const {
      email,
      passwordOne,
      passwordTwo,
      error,
      fake_email
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      this.state.email.includes('yopmail') ||
      email === '' ;

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="9" lg="7" xl="6">
              <Card className="mx-4">
                <CardBody className="p-4">
                  <Form>
                    <h1>Création de compte</h1>
                    <p className="text-muted">Remplissez les champs suivants</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>@</InputGroupText>
                      </InputGroupAddon>
                      <Input
                          name="email"
                          value={email}
                          onChange={this.onChange}
                          type="text"
                          placeholder="Adresse Email"
                      />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="cui-lock-locked"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="passwordOne"
                        value={passwordOne}
                        onChange={this.onChange}
                        type="password"
                        placeholder="Mot de passe"
                      />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="cui-lock-locked"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="passwordTwo"
                        value={passwordTwo}
                        onChange={this.onChange}
                        type="password"
                        placeholder="Confirmer mot de passe"
                      />
                    </InputGroup>
                    <div>
                      {fake_email}
                      {error && <p>{error.message}</p>}
                    </div>
                    <Button disabled={isInvalid} color="success" onClick={this.onSubmit}>Créer le compte</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

    );
  }
}


//Pour appeler firebase et le router
//const SignUpForm = withRouter(withFirebase(SignUpFormBase));
const SignUpForm = compose(
  withRouter,
  withFirebase,
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm };
