import React from 'react';

class Services extends React.Component {
  render() {
  	return (
        <section className="section pt-5" id="services">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <h1 className="section-title text-center">Qu'est-ce que RentaFirst?</h1>
                        <div className="section-title-border margin-t-20"></div>
                        <p className="section-subtitle text-muted text-center padding-t-30 font-secondary">
                        Fini les recherches fastidieuses de biens immobiliers sur <b>plusieurs sites</b>, fini de jongler entre toutes les alertes et le temps perdu à éplucher les annonces et à les comparer. {<br/>}
                        RentaFirst est une technologie très avancée qui vous permet de balayer tout ce temps perdu en identifiant directement les <b>biens les plus rentables</b>. </p>
                    </div>
                </div>
                <div className="row margin-t-30">
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-timer text-custom"></i>
                            <h4 className="padding-t-15">Gain de temps considérable</h4>
                            <p className="padding-t-15 text-muted">
                            Fini l’enchaînement des visites pour trouver les <b>meilleures opportunités</b>. Avec Rentafirst, elles sont disponibles en <b>quelques clics</b>. Plus de sauts de lien en lien, de PAP à SeLoger, RentaFirst compare les plus grandes plateformes pour <b>trouver la perle immobilière</b>.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-cash text-custom"></i>
                            <h4 className="padding-t-15">Revenus décuplés</h4>
                            <p className="padding-t-15 text-muted">
                            Rentafirst met en avant les <b>investissements les plus rentables</b> parmi tous ceux disponibles.
                            Ainsi, votre abonnement est très largement compensé.
                            Oubliez l'effort d'épargne:
                            RentaFirst, c’est <b>l’assurance de dégager un vrai cashflow</b> et de pouvoir <b>multiplier les opérations</b>.{<br/>}
                            Trouver des <b>rentabilités supérieures à 10 % </b>devient d’une <b>facilité déconcertante</b>.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-display1 text-custom"></i>
                            <h4 className="padding-t-15">Organisation simplifiée</h4>
                            <p className="padding-t-15 text-muted">
                            Ne vous fatiguez plus avec les recherches, les calculs, les comparaisons. Rentafirst <b>identifie directement les schémas les plus rentables</b>. Les algorithmes de <b>Machine Learning</b> identifient immédiatement quel bien immobilier est le plus lucratif : le studio du quartier A ou le T3 du quartier B.
                            Ses fonctionnalités innovantes estiment pour vous <b>quel est le loyer praticable</b> en fonction du bien immobilier.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  	);
  }
}
export default Services;
