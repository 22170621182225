import {
  isMobile,
  isBrowser,
  browserName,
  mobileVendor,
  mobileModel
} from 'react-device-detect';


const updateLogs = (firebase, user, data) => {
  const date = new Date().toISOString().slice(0,10);
  data['device'] = {
    isMobile: isMobile,
    isBrowser: isBrowser,
    browserName: browserName,
    mobileVendor: mobileVendor,
    mobileModel: mobileModel
  }
  firebase.addLog(date, user, data)
}


export default {updateLogs};
