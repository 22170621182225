import React, { Component } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Fade,
  Table,
  Popover,
  PopoverBody,
  PopoverHeader
} from 'reactstrap';

import FieldManager from '../Common/FieldManager';
import Details from './Details';
import TagManager from './TagManager';

import star_icon from './images/star-icon.png';
import star_icon_selected from './images/star-icon-selected.png';

import { withFirebase } from '../Firebase';


function Favorite(props){
  const picture = props.isSelected === false ? star_icon : star_icon_selected;
  return <img src={picture} style={{zIndex: 999, height: "2em"}} alt="Ajouter aux favoris" />
}


class FilterSelect extends Component {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      isOpen: false
    }
  }

  componentDidMount(){
    if (this.props.pop_smp){
      this.setState({isOpen: true})
      this.props.firebase.statusUpdate('pop_smp')
      setTimeout(()=> this.setState({isOpen: false}), 7000)
    }
  }

  handleChange(e) {
    this.props.onFilterChange_header(this.props.type);
  }

  render(){
    if (this.props.type === this.props.selectedFilter){
       return <th>
                {this.props.name}
                <span>  </span>
                <i className="cui-sort-descending"></i>
              </th>
    }

    return <>
                <th id="Popover1" style={{cursor: 'pointer', textDecorationLine: 'underline'}} onClick={this.handleChange}>
                    {this.props.name}
                </th>
                <Popover trigger="focus" placement="bottom" isOpen={this.state.isOpen} target="Popover1" onClick={() => this.setState({isOpen: false})}>
                  <PopoverHeader>Filtre prix/m²</PopoverHeader>
                  <PopoverBody>En cliquant ici, vous pouvez filtrer les annonces par prix au mètre carré.</PopoverBody>
                </Popover>
          </>
  }
}



const INITIAL_STATE = {
  selectedAnnonce: null,
  clickedAnnonces: [],
  likedAnnonces: [],
  full_liste_liked: [],
  pop_smp: false,
  pop_scroll_right: false,
  fadeIn: true
};

class HomeListBase extends Component {
  constructor() {
    super();
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onLoadMore = this.onLoadMore.bind(this);

    this.state = { ...INITIAL_STATE };
  }

  componentWillMount(){
    if (!this.props.userData.hasOwnProperty('pop_smp')){
      this.setState({pop_smp: true})
    }

    if (this.props.listToDisplay.length > 0){
      this.mobileAlert();
      const liste_clicked = []
      const liste_liked = []
      const full_liste_liked = []
      if (this.props.userData.hasOwnProperty('clicked')) {
        this.props.userData.clicked.forEach(element => {
          if (element.ville === this.props.ville){
            liste_clicked.push(element.id)
          }
        })
        this.setState({clickedAnnonces: liste_clicked})
      }
      if (this.props.userData.hasOwnProperty('liked')) {
        this.props.userData.liked.forEach(element => {
          full_liste_liked.push(element)
          if (element.ville === this.props.ville){
            liste_liked.push(element.id)
          }
        })
        this.setState({likedAnnonces: liste_liked, full_liste_liked: full_liste_liked})
      }
    }
  }

  mobileAlert(){
    if (window.innerWidth < 575 && !this.props.userData.hasOwnProperty('pop_scroll_right')){
      this.setState({pop_scroll_right: true})
      this.props.firebase.statusUpdate('pop_scroll_right')
      setTimeout(()=> this.setState({fadeIn: false}), 7000)
      setTimeout(()=> this.setState({pop_scroll_right: false}), 7800)
    }
  }

  onFilterChange(value){
    this.props.onFilterChange(value)
  }

  onLineSelected(annonce){
    if (this.state.selectedAnnonce !== annonce.id) {
      this.setState({selectedAnnonce: annonce.id})
    } else {
      this.setState({selectedAnnonce: null})
    }
  }

  onLoadMore(){
    this.props.onLoadMore()
  }

  onAnnonceClick(event, url, id){
    event.stopPropagation();
    this.props.firebase.addClicked(this.props.ville, id)
    window.open(url, "_blank")
    this.setState(state => {
      const clickedAnnonces = [...state.clickedAnnonces, id];
      return {
        clickedAnnonces
      };
    });
  }


  onAnnonceFav(event, data){
    event.stopPropagation();
    if (this.state.likedAnnonces.includes(data.id)){
      //Supprimer
      const updated_likedAnnonces = this.state.likedAnnonces.filter(item => item !== data.id)
      const full_liste_liked = this.state.full_liste_liked.filter(item => item.id !== data.id)
      this.setState({likedAnnonces: updated_likedAnnonces, full_liste_liked:full_liste_liked});
      this.props.firebase.deleteLiked(data, full_liste_liked)
    } else {
      //Ajouter
      this.setState(state => {
        const likedAnnonces = [...state.likedAnnonces, data.id];
        const full_liste_liked = [...state.full_liste_liked, data];
        return {
          likedAnnonces, full_liste_liked
        };
      });
      this.props.firebase.addLiked(this.props.ville, data, this.state.full_liste_liked)
    }
  }

  render(){
    if (this.props.listToDisplay.length > 0) {
      return  <div>
                <Card>
                  {this.state.pop_scroll_right &&
                    <Fade in={this.state.fadeIn} >
                      <Alert color="primary">
                        Glissez vers la droite pour voir les rentabilités et l'accès aux annonces
                        <i className="mdi mdi-arrow-right"></i>
                      </Alert>
                    </Fade>
                  }
                  <CardBody>
                    <Table hover responsive>
                      <thead align="center">
                      <tr align="center">
                        <th>Favoris</th>
                        <th>Prix</th>
                        <th>Surface</th>
                        <FilterSelect name="Prix/m²" type="square_meter_price" onFilterChange_header={this.onFilterChange} selectedFilter={this.props.selectedFilter} firebase={this.props.firebase} pop_smp={this.state.pop_smp}/>
                        <th>Loyer estimé</th>
                        <FilterSelect name="Rentabilité" type="renta" onFilterChange_header={this.onFilterChange} selectedFilter={this.props.selectedFilter} pop_smp={false}/>
                        <th>Lien</th>
                        <th>Tag</th>
                      </tr>
                      </thead>

                      {this.props.listToDisplay.map((annonce) => {
                        return <tbody align="center" key={'tbody'+ annonce.id}>
                                <tr align="center" key={annonce.id} style={this.state.clickedAnnonces.includes(annonce.id)  ? {backgroundColor: "#eee"} : {}} onClick={() => this.onLineSelected(annonce)}>
                                  <td key={'fav'+ annonce.id}>
                                    <Button onClick={(e) => this.onAnnonceFav(e, annonce)} color="link">
                                      {this.state.likedAnnonces.includes(annonce.id)
                                        ? <Favorite isSelected={true} />
                                        : <Favorite isSelected={false}  />
                                      }
                                    </Button>
                                  </td>
                                  <td key={'prix_'+ annonce.id}><FieldManager value={{'prix': annonce.prix}}/></td>
                                  <td key={'surface'+ annonce.id}><FieldManager value={{'surface': annonce.surface}}/></td>
                                  <td key={'sqm'+ annonce.id}><FieldManager value={{'square_meter_price': annonce.square_meter_price}}/></td>
                                  <td key={'loyer_'+ annonce.id}><FieldManager value={{'loyer': annonce.loyer}} /></td>
                                  <td key={'renta_'+ annonce.id}><FieldManager value={{'renta': annonce.renta}} /></td>
                                  <td key={'lien'+ annonce.id}>
                                    <Button onClick={(e) => this.onAnnonceClick(e, annonce.lien, annonce.id)} color="link">Accès à l'annonce</Button>
                                  </td>
                                  <td key={'tags'+ annonce.id}>
                                    <TagManager data={annonce.data}/>
                                  </td>
                                </tr>
                                <Details colSpanProp={"8"} selectedAnnonce={this.state.selectedAnnonce} annonce={annonce} isClicked={this.state.clickedAnnonces.includes(annonce.id)} />
                              </tbody>
                      })}
                      <tbody>
                        <tr>
                          <th colSpan="8" style={{textAlign: 'center', cursor: 'pointer'}} onClick={this.onLoadMore}>
                              Charger plus d'annonces
                          </th>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>

              </div>
    }
    return null
  }
}

const HomeList = withFirebase(HomeListBase);
export default HomeList;
