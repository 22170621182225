import React from 'react';
import {
  CardHeader,
  CardTitle,
  CardText,
  CardBody,
  Card
} from 'reactstrap';

const MentionsLegales = (props) => {
  return (
    <div style={{
      width: "60%",
      paddingTop: "20px",
      marginLeft: "auto",
      marginRight: "auto",

    }}>
      <Card>
        <CardHeader id="first">
          <CardTitle style={{textAlign: "center"}}> <b> Mentions Légales </b> </CardTitle>
        </CardHeader>
        <CardBody>
          <CardText>Some quick example text to build on the card title and make up the bulk of the card's content.</CardText>
        </CardBody>
      </Card>

    </div>
  );
}

export default MentionsLegales;
