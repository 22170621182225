import React, { useState } from 'react';
import {
  Col,
  Row,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators
} from 'reactstrap';

const PhotoDisplayer = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === props.photos.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? props.photos.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = props.photos.map((item) => {
    return (
      <CarouselItem
        key={item}
      >

        <img src={item} alt="Photos du bien" style={{height: '200px', margin: 'auto', display: 'block', width: 'auto'}} />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <CarouselIndicators items={props.photos} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
}


function Details(props){
  if (props.selectedAnnonce === props.annonce.id && props.annonce.hasOwnProperty('photos')){
    return  <tr>
              <th colSpan={props.colSpanProp}>
              <Row>
                <Col xs="2">
                  {props.annonce.photos.length > 0 &&
                    <div style={{width: 'auto', height: '200px'}} >
                      <PhotoDisplayer photos={props.annonce.photos} />
                    </div>
                  }
                </Col>
                <Col>
                  <div style={{'fontWeight': 'normal'}}>
                    {props.annonce.descriptif}
                  </div>
                </Col>
                <Col style={{fontWeight: "normal", fontStyle: "italic", paddingTop:"30px"}}>Nouvelle fonctionnalité à venir ici</Col>
              </Row>
              </th>
            </tr>
  } else if (props.selectedAnnonce === props.annonce.id && !props.annonce.hasOwnProperty('photos')){
    return  <tr>
              <th colSpan={props.colSpanProp}>
              <Row>
                <Col>
                  Description:
                  <div style={{'fontWeight': 'normal'}}>
                    {props.annonce.descriptif}
                  </div>
                </Col>
                <Col style={{fontWeight: "normal", fontStyle: "italic", paddingTop:"30px"}}>
                  Nouvelle fonctionnalité à venir ici

                </Col>
              </Row>
              </th>
            </tr>
  } else {
    return null
  }
}

export default Details;
