import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Row,
  Spinner,
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader
} from 'reactstrap';
import LoadingBar from 'react-top-loading-bar';

import HomeList from './HomeList';
import Modal from './Modal';
import CityPicker from '../Common/CityPicker';
import styles from './Home.module.css';
import quick_functions from './functions.js';
import common_functions from '../Common/functions.js';


//import { StickyContainer, Sticky } from 'react-sticky';


import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';

const Home = () => (
  <div>
    <RequestForm/>
  </div>
);

const INITIAL_STATE = {
  listeVille: [],
  selectedCity: '',
  selectedAmount: '',
  disabledOption: false,
  listAnnonces_renta: [],
  listAnnonces_smp: [],
  listAnnonces_toDisplay: [],
  filter: "renta",
  userData: {},
  timer: null,
  loadingBarProgress: 0,
  isDesktop: window.innerWidth > 575,
  invalidCity: false,
  show_modal: false
};

class RequestFormBase extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.state = { ...INITIAL_STATE };
    this.onChange = this.onChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onLoadMore = this.onLoadMore.bind(this);
  }

  componentWillMount(){
    const here = this
    quick_functions.prepareData(this.props.firebase, function(liste_villes, userData){
      //console.log(!userData.hasOwnProperty('show_modal'))
      here.setState({listeVille: liste_villes, userData: userData, show_modal: !userData.hasOwnProperty('show_modal')});
    })
  }

  componentDidMount(){
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  paramChange = () => {
    var filtered_listeVille =[]
    if (this.state.selectedAmount !== ''){
      if (this.state.filter === 'renta') {
        filtered_listeVille = this.state.listAnnonces_renta.filter(annonce => annonce.prix < this.state.selectedAmount)
        this.setState({listAnnonces_toDisplay: filtered_listeVille})
      } else {
        filtered_listeVille = this.state.listAnnonces_smp.filter(annonce => annonce.prix < this.state.selectedAmount)
        this.setState({listAnnonces_toDisplay: filtered_listeVille})
      }
    } else {
      if (this.state.filter === 'renta') {
        this.setState({listAnnonces_toDisplay: this.state.listAnnonces_renta})
      } else {
        this.setState({listAnnonces_toDisplay: this.state.listAnnonces_smp})
      }
    }
  }

  getUserData = () => {
    // permet de récupérer les données de l'utilisateur (favoris, clicked, email etc.)
    this.props.firebase.userData(this.state.userData.uid)
      .then(user_doc => {
          const userData = user_doc.data()
          userData['uid'] = this.state.userData.uid
          this.setState({userData: userData})
      })
  }

  startTimer = () => {
    // Sert à faire croire qu'il y a un temps de requête nécessaire... lol
    this.setState({timer: true, loadingBarProgress: 10})
    this.myInterval = setInterval(() => {
      if (this.state.loadingBarProgress < 100) {
        const step = Math.random()*60
        this.setState({loadingBarProgress: this.state.loadingBarProgress + step})
      } else {
        this.setState({timer: false, loadingBarProgress: 0})
        window.scrollTo({
          top: this.myRef.current.offsetTop,
          left: 0,
          behavior: 'smooth'
        })
        clearInterval(this.myInterval)
      }
    }, 650)
  }

  onSubmit = event => {
    this.setState({listAnnonces_renta: [], listAnnonces_smp: [], listAnnonces_toDisplay: []})
    this.requestData(this.state.filter)
    if (this.state.selectedCity === '') {this.setState({invalidCity: true})} else { this.setState({invalidCity: false}) }
  };

  requestData = type => {
    if (this.state.selectedAmount < 0 || this.state.selectedCity === ''){
      console.log('Montant inaproprié ou ville manquante')
    } else {
      var listAnnonces = []
      this.props.firebase.getAnnonces(this.state.selectedCity, type)
        .then(snapshot => {
          snapshot.forEach(doc => {
            quick_functions.formatAnnonce(doc.id, doc.data(), function(annonce){
              listAnnonces.push(annonce)
            })
          });
        })
        .then(() => {
          if (this.state.filter === 'renta'){
            this.setState({listAnnonces_renta: listAnnonces})
          } else {
            this.setState({listAnnonces_smp: listAnnonces})
          }
          this.getUserData();
          this.startTimer();
          common_functions.updateLogs(this.props.firebase, this.state.userData.uid, {
            action: 'search',
            ville: this.state.selectedCity,
            email: this.state.userData.email
          })
          this.paramChange();
        })
        .catch(err => {
          console.log('Error getting documents', err);
        });
    }
  }

  onChange = event => {
    this.setState({
          [event.target.name]: event.target.value
      }, () => {
          this.paramChange();
      });
   };

  onCityChange = city => {
    this.setState({selectedCity: city});
  };

  onFilterChange(value){
    this.setState({filter: value})
    this.requestData(value);
    this.paramChange();
  }

  onLoadMore(){
    const seuil = this.state.listAnnonces_toDisplay[this.state.listAnnonces_toDisplay.length - 1][this.state.filter]
    var listAnnonces = this.state.listAnnonces_toDisplay
    this.props.firebase.getMore(this.state.selectedCity, this.state.filter, seuil)
    .then(snapshot => {
      snapshot.forEach(doc => {
        quick_functions.formatAnnonce(doc.id, doc.data(), function(annonce){
          if (!quick_functions.containsObject(annonce, listAnnonces)){
            listAnnonces.push(annonce)
          }
        })
      })
    })
    .then(() => {
      if (this.state.filter === 'renta'){
        this.setState({listAnnonces_renta: listAnnonces})
      } else {
        this.setState({listAnnonces_smp: listAnnonces})
      }
      this.paramChange();
    })
    .catch(err => {
      console.log('Error getting documents', err);
    });
  }

  render() {
    return (
      <div className="animated fadeIn">
            <Card>
              <CardHeader>
                <strong>Recherche</strong> simple
              </CardHeader>
              <CardBody>
              <Row className="justify-content-md-center">
                <Form inline onSubmit={e => {
                  e.preventDefault();
                  this.onSubmit(e)
                }}>
                  <Col>
                    <div style={this.state.isDesktop ? { marginTop: "20px" } : { marginTop: "0px" }}>
                      <CityPicker selectedCity={this.selectedCity} onCityChange={this.onCityChange} invalid={this.state.invalidCity}/>
                    </div>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Input
                        type="number"
                        name="selectedAmount"
                        placeholder="Montant maximum"
                        step={10000}
                        value={this.state.selectedAmount}
                        onChange={this.onChange}
                        style={this.state.isDesktop ? {marginTop: "18px", marginBottom: "0px"} : {marginTop: "15px", marginBottom: "0px"}}
                      />
                      <FormText color="muted" style={{marginTop: "0px"}}>
                        Facultatif
                      </FormText>
                    </FormGroup>
                  </Col>
                  {this.state.isDesktop &&
                    <Col>
                        <Button disabled={this.state.loadingBarProgress > 0} onClick={this.onSubmit} block outline color="success">
                          Rechercher
                        </Button>
                    </Col>
                  }
                </Form>
                </Row>
                {!this.state.isDesktop &&
                  <Row>
                      <Button disabled={this.state.loadingBarProgress > 0} onClick={this.onSubmit} block outline color="success">
                        Rechercher
                      </Button>
                  </Row>
                }
              </CardBody>
              <CardFooter>
                <Button id="details" size="sm" color="ghost-light" className={styles.btn_footer}> Détails </Button>
                <UncontrolledPopover trigger="focus" placement="left" target="details">
                  <PopoverHeader>Détails liés à la ville</PopoverHeader>
                  <PopoverBody>
                    Prochainement nous mettrons des informations liées à la ville recherchée ici pour vous assister dans votre recherche.
                  </PopoverBody>
                </UncontrolledPopover>
              </CardFooter>
            </Card>
            <div ref={this.myRef}>
              {this.state.timer
              ? <div>
                  <LoadingBar
                    progress={this.state.loadingBarProgress}
                    height={3}
                    color='#70A351'
                    onLoaderFinished={() => this.setState({time: false})}
                  />
                  {this.state.loadingBarProgress > 0 &&
                    <Spinner
                      color="success"
                      style={{position: "fixed", top: "50%", left: "50%"}}
                    />
                  }
                </div>
              : <HomeList
                  listToDisplay={this.state.listAnnonces_toDisplay}
                  selectedFilter={this.state.filter}
                  onFilterChange={this.onFilterChange}
                  onLoadMore={this.onLoadMore}
                  ville={this.state.selectedCity}
                  userData={this.state.userData}
                />
              }
            </div>
            <Modal firebase={this.props.firebase} show_modal={this.state.show_modal}/>
      </div>
    );
  }
}

const condition = authUser => !!authUser;

const RequestForm = withFirebase(RequestFormBase);

export default withAuthorization(condition)(Home);
