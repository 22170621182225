import React, { Component } from 'react';
import {
  FormGroup,
  Input
} from 'reactstrap';

import { withFirebase } from '../Firebase';


const INITIAL_STATE = {
  listeVille: [],
  selectedCity: ''
};

class CityPickerBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentWillMount(){
    this.props.firebase.getData("liste_villes")
    .then(doc => {
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        this.setState(state => {
          const listeVille = doc.data().villes_ready;
          return {listeVille}
        });
      }
    })
    .catch(err => {
      console.log('Error getting document', err);
    });
  }

  onSelectChange = event => {
   this.setState({selectedCity: event.target.value})
   this.props.onCityChange(event.target.value)
  }

  render() {
    return (
      <div>
        <FormGroup>
          <Input
            type="select"
            name="selectedCity"
            value={this.state.selectedCity}
            onChange={(e) => this.onSelectChange(e)}
            invalid={this.props.invalid}
          >
            <option key="default" value="default" disabled={this.state.selectedCity !== ''}>Choisir une ville</option>
            {this.state.listeVille.map((ville) => (
              <option key={ville} value={ville}>{ville}</option>
            ))}
          </Input>
        </FormGroup>
      </div>
    );
  }
}


const CityPicker = withFirebase(CityPickerBase);
export default CityPicker;
