import app from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import * as firebase from 'firebase';

var config = {
  apiKey: "AIzaSyAaaRnqu4ey9rmktqf5NdPIPOkdvyFF06o",
  authDomain: "realert-6419f.firebaseapp.com",
  databaseURL: "https://realert-6419f.firebaseio.com",
  projectId: "realert-6419f",
  storageBucket: "realert-6419f.appspot.com",
  messagingSenderId: "331986374128",
  appId: "1:331986374128:web:204e008b3cac87f7"
};


class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.firestore = app.firestore();

  }



  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
  this.auth.createUserWithEmailAndPassword(email.trim(), password);

  doSignInWithEmailAndPassword = (email, password) =>
  this.auth.signInWithEmailAndPassword(email.trim(), password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email.trim());

  doPasswordUpdate = password =>
  this.auth.currentUser.updatePassword(password);

  getUid = (callback) => {
    this.auth.onAuthStateChanged(function(user) {
      if (user) {
        //console.log(user.uid)
        callback(user.uid)
      } else {
        console.log('no user signed in')
        callback(null)
      }
    });
  }


  // *** Database API ***
  getData = (doc) => this.firestore.collection("FRONT").doc(doc).get();
  getAnnonces = (city, type) => {
    if (type === 'renta'){
      return this.firestore.collection("VILLES").doc(city).collection("ACHAT").orderBy("renta", "desc").limit(10).get();
    } else {
      return this.firestore.collection("VILLES").doc(city).collection("ACHAT").orderBy("square_meter_price", "asc").limit(10).get();
    }
  }

  getMore = (city, type, seuil) => {
    if (type === 'renta'){
      return this.firestore.collection("VILLES").doc(city).collection("ACHAT").orderBy("renta", "desc").where("renta", "<", seuil).limit(10).get();
    } else {
      return this.firestore.collection("VILLES").doc(city).collection("ACHAT").orderBy("square_meter_price", "asc").where("square_meter_price", ">", seuil).limit(10).get();
    }
  }

  addCity = (data) => this.firestore.collection("LISTE_URL").add(data);
  updateListeUser = (toutes_villes) => this.firestore.collection("FRONT").doc("liste_villes").update(toutes_villes);


  //Users
  userData = (uid) => this.firestore.collection("USERS").doc(uid).get();
  createUser = (uid) => this.firestore.collection("USERS").doc(uid);

  //Alerts
  addAlert = (uid, ville, renta) => this.firestore.collection("USERS").doc(uid).update({
    alertes: firebase.firestore.FieldValue.arrayUnion({ville: ville, renta: renta}),
    alerte: true
  })

  deleteAlert = (uid, ville, renta) => this.firestore.collection("USERS").doc(uid).update({
    alertes: firebase.firestore.FieldValue.arrayRemove({ville: ville, renta: renta}),
    alerte: true
  })

  deleteLastAlert = (uid, ville, renta) => this.firestore.collection("USERS").doc(uid).update({
    alertes: firebase.firestore.FieldValue.arrayRemove({ville: ville, renta: renta}),
    alerte: false
  })

  alertActivation = (uid, value) => this.firestore.collection("USERS").doc(uid).update({alerte: value})

  // Clicked & Liked
  addClicked = (ville, id) => {
    const firestore = this.firestore
    let data = {}
    data['clicked'] = firebase.firestore.FieldValue.arrayUnion({id: id, ville: ville});
    this.getUid(function(uid){
      firestore.collection("USERS").doc(uid).update(data)
    })
  }

  addLiked = (ville, annonce) => {
    const firestore = this.firestore
    let to_upload = {}
    to_upload['liked'] = firebase.firestore.FieldValue.arrayUnion(Object.assign(annonce.data, {ville: ville, id: annonce.id}));
    this.getUid(function(uid){
      firestore.collection("USERS").doc(uid).update(to_upload)
    })
  }

  deleteLiked = (annonce_todelete, liked) => {
    const firestore = this.firestore
    const new_liked = liked.filter(annonce => annonce.id !== annonce_todelete)
    this.getUid(function(uid){
      firestore.collection("USERS").doc(uid).update({
        liked: new_liked
      })
      .catch(function(error) {
          console.error("Error removing document: ", error);
      });
    })
  }

  // Logs
  statusUpdate = (pop_up) => {
    // Cette fonction sert à mettre à jour quand quelque chose à été affiché et qu'il ne doit être affiché qu'une seule fois à l'utilisateur
    let to_upload = {}
    to_upload[pop_up] = true;
    const firestore = this.firestore
    this.getUid(function(uid){
      firestore.collection("USERS").doc(uid).update(to_upload)
      .catch(function(error) {
          console.error("Error removing document: ", error);
      });
    })
  }

  addLog = (date, user, data) => {
    // on récupère la date au format YYMMDD
    // Cette fonction enregistre des activités de l'individu
    // ATTENTION : ELLE EST APPELEE PAR UNE COMMON_FUNCTION

    var dateNow = Date.now();
    data['timestamp'] = firebase.firestore.Timestamp.fromDate(new Date(dateNow));
    let to_upload = {}
    to_upload[user] = firebase.firestore.FieldValue.arrayUnion(data);
    this.checkExist(date, to_upload)
  }

  checkExist = (date, to_upload) => {
    this.firestore.collection("LOGS").doc(date).get()
      .then((docSnapshot) => {
          if (docSnapshot.exists) {
            this.firestore.collection("LOGS").doc(date).update(to_upload)
          } else {
            this.firestore.collection("LOGS").doc(date).set(to_upload)
          }
      })
  }
}

export default Firebase;
