import React from 'react';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
  render() {
  	return (
         <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 margin-t-20">
                        <h4>Information</h4>
                        <div className="text-muted margin-t-20">
                            <ul className="list-unstyled footer-list">
                                <li><Link to="/faq">FAQ</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 margin-t-20">
                        <h4>Contact</h4>
                        <div className="text-muted margin-t-20">
                        <p className="text-muted margin-t-20"><i className="pe-7s-mail-open"></i>&nbsp; admin@rentafirst.com</p>
                            <ul className="list-inline social margin-t-20">
                                <li className="list-inline-item social-icon" onClick={()=> window.open("https://www.facebook.com/Renta-First-110694050413090/", "_blank")}> <i className="mdi mdi-facebook"></i></li>
                                <li className="list-inline-item social-icon" onClick={()=> window.open("https://www.instagram.com/rentafirst/", "_blank")}> <i className="mdi mdi-instagram"></i></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
  	);
  }
}
export default Footer;
