import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ModalExample = (props) => {
  const [modal, setModal] = useState(props.show_modal);
  const [toggled, setToggle] = useState(false);

  useEffect(()=> {
    if(props.show_modal && !toggled){
      setModal(props.show_modal)
    }
  })

  const toggle = () => {
    setModal(!modal);
    setToggle(true)
    props.firebase.statusUpdate('show_modal')

  }

  const closeBtn = <button className="close" onClick={toggle}>&times;</button>;

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} close={closeBtn}>Bienvenue sur RentaFirst!</ModalHeader>
        <ModalBody>
          <h6>Plusieurs fonctionnalités sont disponibles:</h6>
          <p>
          Dans l'onglet <i>Trouver une affaire</i>, vous pouvez sélectionner une ville et faire s'afficher les annonces les plus rentables.
          Pensez aussi que vous pouvez filtrer par prix/m² et ajouter des annonces à vos favoris.
          </p>
          <p>
          Ces favoris se retrouvent ensuite dans l'onglet <i>Favoris</i>.
          </p>
          <p>
          Enfin, vous pouvez ajouter des notifications email selon vos critères (dans l'onglet <i>Alertes</i>) et recevoir les meilleures annonces quotidiennement
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggle}>Top!</Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalExample;
