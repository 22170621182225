import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Spinner
} from 'reactstrap';

import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const SignInPage = () => (
  <div>
    <SignInForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  isLoading: false,
  isDesktop: window.innerWidth > 575
};


class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;
    this.setState({isLoading: true})
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({isLoading: false})
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="text-white bg-primary py-5" style={this.state.isDesktop ? { width: '50%' } : { width: '100%' }}>
                  <CardBody className="text-center">
                    <div>
                      <h2>Créez votre compte</h2>
                      <p>{"En créant votre compte vous pourrez accéder aux meilleures annonces en quelques clics"} </p>
                      <Link to={ROUTES.SIGN_UP}>
                        <Button color="primary" className="mt-3" active tabIndex={-1}>
                          {"S'inscrire!"}
                        </Button>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
                <Card className="p-4">
                  <CardBody>
                    <Form>
                      <h1>Connexion</h1>
                      <p className="text-muted">Connectez vous à votre compte</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="cui-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="email"
                          value={email}
                          onChange={this.onChange}
                          type="text"
                          placeholder="Adresse email"
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="cui-lock-locked"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="password"
                          value={password}
                          onChange={this.onChange}
                          type="password"
                          placeholder="Mot de passe"
                        />
                      </InputGroup>
                      {error && <p>{error.message}</p>}
                      <Row>
                        <Col xs="5">
                            {this.state.isLoading
                              ? <Button disabled={isInvalid} color="primary" className="px-4">
                                  <Spinner color="light" />
                                </Button>
                              : <Button onClick={this.onSubmit} disabled={isInvalid} color="primary" className="px-4">
                                  Connexion
                                </Button>
                            }
                        </Col>
                        <Col xs="7" className="text-right">
                          <PasswordForgetLink />
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

export default SignInPage;

export { SignInForm };
