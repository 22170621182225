import React, { Component } from 'react';
import * as ROUTES from '../../../constants/routes';
import { Link } from 'react-router-dom';


class Navbar extends Component {

  constructor() {
    super();
    this.state = {
      classNames: "collapse navbar-collapse"
    };
  }

  onClick = () => {
    if (this.state.classNames === "collapse navbar-collapse"){
      this.setState({classNames: "collapse navbar-collapse show"})
    } else {
      this.setState({classNames: "collapse navbar-collapse"})      
    }
  }

  render() {
  	return (
        <nav className="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark">
            <div className="container">
                <div className="navbar-brand logo text-uppercase" >
                    <a style={{fontWeight: 700}} >
                      RENTA {' '}
                    </a>
                    <a style={{fontWeight: 410}} >
                      FIRST
                    </a>
                </div>
                <button className="navbar-toggler" type="button" onClick={this.onClick}>
                    <i className="mdi mdi-menu"></i>
                </button>
                <div className={this.state.classNames} id="navbarCollapse">
                    <ul className="navbar-nav navbar-center" id="mySidenav">
                        <li className="nav-item active">
                            <a href="#home" className="nav-link">Accueil</a>
                        </li>
                        <li className="nav-item">
                            <a href="#services" className="nav-link">Qu'est-ce que RentaFirst?</a>
                        </li>
                        <li className="nav-item">
                            <a href="#features" className="nav-link">Fonctionnalités</a>
                        </li>
                        <li className="nav-item">
                            <a href="#pricing" className="nav-link">Tarifs</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link"><Link to={ROUTES.FAQ}>FAQ</Link></a>
                        </li>
                    </ul>
                    <div className="nav-button ml-auto">
                        <ul className="nav navbar-nav navbar-right">
                            <li>
                                <Link to={ROUTES.HOME}>
                                  <button style={{ color: 'white'}} className="btn btn-custom navbar-btn btn-rounded waves-effect waves-light">
                                      Accès au site
                                  </button>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
  	);
  }
}

export default Navbar;
