export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const SEARCH = '/search';
export const ALERTS = '/alertes';
export const FAVORIS = '/favoris';
export const FAQ = '/faq';
export const MENTIONS = '/mentions';
export const HASHTAGS = '/hashtags';
