import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import Autosuggest from 'react-autosuggest';
import { Alert } from 'reactstrap';
import request from 'request';


// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.Display;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
    <div>
      {suggestion.Display}
    </div>
);

class SearchBase extends Component {
  constructor() {
    super();
    this.state = {
      message:'',
      error:'',
      toutes_villes: [],
      villes_ready: [],
      value: '',
      suggestions: []
    };
  }

  componentWillMount(){
    this.props.firebase.getData("liste_villes")
    .then(doc => {
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        this.setState(state => {
          const toutes_villes = doc.data().toutes_villes;
          const villes_ready = doc.data().villes_ready;
          return {toutes_villes, villes_ready}
        });
      }
    })
    .catch(err => {
      console.log('Error getting document', err);
    });
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      message: ''
    });

    var get_suggestions = new Promise((resolve, reject) => {
      request(`https://autocomplete.svc.groupe-seloger.com/api/v2.0/auto/complete/fra/63/10/8/SeLoger?text=${value}`, function (error, response, body) {
        //console.log(body)
        var filtered_data = Array.from(JSON.parse(body)).filter(element => element.Type === "Ville")
        resolve(filtered_data)
      });
    })
    get_suggestions.then((retrieved_data) => {
      this.setState({ suggestions: retrieved_data })
    });
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    var toutes_villes = this.state.toutes_villes;
    var villes_ready = this.state.villes_ready;

    if (villes_ready.includes(suggestion.Tag)){
      this.setState({ message: 'Cette ville est déjà disponible mon ami!' });
    } else if (toutes_villes.includes(suggestion.Tag) ){
      this.setState({ error: 'Cette ville sera prochainement disponible, nous manquons de données pour l\'instant ' });
    } else {
      this.setState({
        message: 'Merci! Cette ville est ajoutée et sera disponible prochainement',
        toutes_villes: toutes_villes
      });
      var dateNow = Date.now() - 86400000000;
      var url_achat = 'https://www.seloger.com/list.htm?types=1&projects=2&enterprise=0&natures=1&places=[{ci:'+ suggestion.Params.ci +'}]&qsVersion=1.0'
      var url_loc = 'https://www.seloger.com/list.htm?types=1&projects=1&enterprise=0&places=[{ci:'+ suggestion.Params.ci +'}]&qsVersion=1.0'
      this.props.firebase.updateListeUser({toutes_villes: Array.from(toutes_villes)})
      this.props.firebase.addCity({
        type: 'ACHAT',
        ville: suggestion.Tag,
        lastRequest: new Date(dateNow),
        SeLoger: url_achat
      });
      this.props.firebase.addCity({
        type: 'LOCATION',
        ville: suggestion.Tag,
        lastRequest: new Date(dateNow),
        SeLoger: url_loc
      })

    }
  };


  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Taper un nom de ville',
      value,
      onChange: this.onChange
    };

    let message;
    if (this.state.message !== '') {
      message =       <Alert color="success">
                        {this.state.message}
                      </Alert>
    } else if (this.state.error !== ''){
      message =       <Alert color="warning">
                        {this.state.error}
                      </Alert>
    }

    // Finally, render it!
    return (
      <div>
        {message}
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
      </div>
    );
  }
}


const Search = withFirebase(SearchBase);
export default Search;
