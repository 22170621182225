import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import {
  Card, CardBody,
  CardTitle, CardSubtitle, Button, Col, Label, Input, Row
} from 'reactstrap';

import { AppSwitch } from '@coreui/react';

import AlertesList from './AlertesList';
import CityPicker from '../Common/CityPicker';

class AlertBase extends Component {
  constructor() {
    super();
    this.state = {
      uid:'',
      selectedCity: '',
      selectedRenta: '',
      message:'',
      alerte_active: true,
      liste_alertes: [],
      key:'',
      isDesktop: window.innerWidth > 575
    };
  }

  onClick = () => {
    if (this.state.selectedCity === ''){
      this.setState({message: 'Veuillez choisir une ville'})
    } else if (this.state.liste_alertes.find(element => element.ville === this.state.selectedCity)) {
      this.setState({message: 'La ville choisie figure déjà parmi vos alertes'})
    } else if (this.state.selectedRenta < 5) {
      this.setState({message: 'Veuillez choisir une rentabilité supérieur à 5%'})
    } else {
      this.setState({message: '', key: Math.random()})
      this.props.firebase.addAlert(this.state.uid, this.state.selectedCity, this.state.selectedRenta)
    }
  }

  onActivation = () => {
    this.props.firebase.alertActivation(this.state.uid, !this.state.alerte_active)
    .then(this.setState({alerte_active: !this.state.alerte_active}))
  }

  onChange = event => {
    this.setState({
          [event.target.name]: event.target.value
    });
  };

  onCityChange = city => {
    this.setState({selectedCity: city});
  };

  onUidRetrieved = uid => {
    this.setState({uid: uid});
  }

  onAlertsRetrieved = (alertes, active) => {
    this.setState({liste_alertes: alertes, alerte_active: active});
  }

  render() {
    return (
      <div>
        <Card>
          <CardBody>
            <Row>
            <Col>
              <CardTitle><b>Configurez des alertes </b> </CardTitle>
              <CardSubtitle style={{paddingBottom: '15px'}}><i>Pour recevoir par email les annonces qui correspondent à vos critères (tous les matins) </i></CardSubtitle>
            </Col>
            <Col sm="4">
              <AppSwitch onChange={this.onActivation} className={'mx-1'} variant={'3d'} color={'success'} checked={this.state.alerte_active} label dataOn={'\u2713'} dataOff={'\u2715'}/>
            </Col>
            </Row>
            <Row>
              <Col xs="6" sm="4">
                <Label>Ville</Label>
                <CityPicker selectedCity={this.selectedCity} onCityChange={this.onCityChange}/>
              </Col>
              <Col xs="6" sm="4">
                <Label>Rentabilité (%)</Label>
                <Input
                  type="number"
                  name="selectedRenta"
                  placeholder="Entrez un nombre"
                  step={1}
                  value={this.state.selectedRenta}
                  onChange={this.onChange}
                />
              </Col>
              <Col xs="6" sm="4">
              {this.state.isDesktop ? (
                <Button onClick={this.onClick} style={{"position":"absolute",  bottom: 20 }} color="success">Ajouter</Button>
              ):(
                <Button onClick={this.onClick} style={{ bottom: 20 }} color="success">Ajouter</Button>
              )}
              </Col>
            </Row>
            <div style={{ color: 'red' }}> {this.state.message} </div>
          </CardBody>
        </Card>
        <AlertesList onUidRetrieved={this.onUidRetrieved} onAlertsRetrieved={this.onAlertsRetrieved} key={this.state.key} active={this.state.alerte_active}/>
      </div>
    );
  }
}


const Alerte = withFirebase(AlertBase);
export default Alerte;
