import React, {Component} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Button} from 'reactstrap';

const immo = [
  '#immo',
  '#immobilier',
  '#rentable',
  '#rentier',
  '#locatif',
  '#meublé',
  '#LMNP',
  '#SCI',
  '#revenuspassifs',
  '#cashflow',
  '#reussite',
  '#investissement',
  '#louer',
  '#investissementlocatif',
  '#investisseur',
  '#agentimmobilier',
  '#projetimmobilier',
  '#investissementimmobilier',
  '#entrepreneur',
  '#libertefinanciere',
]

const bourse = [
  '#interetscomposes',
  '#valueinvesting',
  '#warrenbuffet',
  '#buffet',
  '#bourse',
  '#trading',
  '#charliemunger',
  '#benjamingraham',
  '#intelligentinvestor',
  '#theintelligentinvestor',
  '#bullmarket',
  '#tradingstrategy',
  '#dividendes',
  '#buyandhold',
  '#investir',
  '#rentable',
  '#rentier',
  '#revenuspassifs',
  '#cashflow',
  '#reussite',
  '#investissement',
  '#investisseur',
  '#entrepreneur',
  '#libertefinanciere'
]

class Hashtags extends Component {
  constructor() {
    super();
    this.state = {hashtags: ''};
  }


  createHashtags(from_array){
    const nb_hashtags = Math.round(5 + Math.random()*4)
    var new_hashtags = ''
    var array_hashtags = []
    for (var i = 0; i < nb_hashtags ; i++){
      const index_hashtag = Math.round(Math.random()*from_array.length)
      if (!array_hashtags.includes(from_array[index_hashtag])){
        array_hashtags.push(from_array[index_hashtag])
        new_hashtags = new_hashtags + ' ' + from_array[index_hashtag]
      }
    }
    this.setState({hashtags: new_hashtags})
  }


  render() {
    return (
      <div>
        <CopyToClipboard
          text={this.state.hashtags}
          onCopy={() => alert('hashtags copiés, tu le sais')}
        >
          <div>
            <Button color="danger">Copier</Button>
            {this.state.hashtags}
          </div>
        </CopyToClipboard>
        <Button onClick={() => this.createHashtags(bourse)} color="info">Ben Hustle</Button>
        <Button onClick={() => this.createHashtags(immo)} color="success">RentaFirst</Button>
      </div>
    )
  }
}

export default Hashtags;
