import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';

class Started extends React.Component {
  render() {
  	return (
        <section className="section section-lg bg-get-start">
            <div className="bg-overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 text-center">
                        <h1 className="get-started-title text-white">Première recherche offerte</h1>
                        <div className="section-title-border margin-t-20 bg-white"></div>
                        <p className="section-subtitle font-secondary text-white text-center padding-t-30">
                        Reste à voir si vous voulez continuer comme avant, à chercher sur de nombreux sites gratuitement ou {<br/>}
                        si vous préférez avoir une solution qui va chercher pour vous et <b>optimiser votre temps et votre rentabilité</b>…
                        </p>
                        <Link to={ROUTES.HOME} style={{zIndex: 998, color: 'white'}} className="btn btn-custom margin-t-30 waves-effect waves-light">Essai gratuit <i className="mdi mdi-arrow-right"></i></Link>
                    </div>
                </div>
            </div>

        </section>
  	);
  }
}
export default Started;
