import React from 'react';
import {
  Badge
} from 'reactstrap';


function TagManager(props){

  var tags = []

  const nb_jour = parseInt((Date.now()/1000 - props.data.creationDate.seconds)/86400)
  let nb_jour_string
  if (nb_jour > 1){ nb_jour_string = nb_jour + " jours" } else { nb_jour_string = nb_jour + " jour" }

  if (nb_jour < 10){
    tags.push({color: "warning", text: "Récent"}, {color: "warning", text: nb_jour_string})
  } else if (nb_jour > 60){
    tags.push({color: "success", text: nb_jour_string})
  } else {
    tags.push({color: "light", text: nb_jour_string})
  }

  if (props.data.hasOwnProperty('urgent')){
    tags.push({color: "danger", text: "Urgent"})
  }

  if (props.data.hasOwnProperty('travaux')){
    tags.push({color: "info", text: "Travaux"})
  }

  if (props.data.hasOwnProperty('refait')){
    tags.push({color: "success", text: "Refait"})
  }

  if (props.data.hasOwnProperty('isLoue')){
    tags.push({color: "primary", text: "Déjà loué"})
  }

  return tags.map(values => {
    return <Badge className="mr-1" color={values.color}>{values.text}</Badge>
  })

}


export default TagManager;
