import React from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../../constants/routes';

var phantom = {
  display: 'block',
  height: '60px',
  width: '100%',
}

var style = {
    backgroundColor: "#1bbc9d",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    fontSize: "18px",
    padding: "12px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "50px",
    width: "100%",
    zIndex: 999
}

class MobileButton extends React.Component {
  render() {
  	return (
      <div>
          <div style={phantom} />
          <Link to={ROUTES.HOME}>
            <button style={style} >
                Essai gratuit
            </button>
          </Link>
      </div>
  	);
  }
}


export default MobileButton;
