import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import {
  Button,
  Card,
  CardBody,
  Table
} from 'reactstrap';

import common_functions from '../Common/functions.js';


const INITIAL_STATE = {
  liste_alertes: [],
  uid:''
};

class AlertesListBase extends Component {
  constructor() {
    super();

    this.state = { ...INITIAL_STATE };
  }

  onUidRetrieved = uid => {
    this.setState({uid: uid})
    this.props.onUidRetrieved(uid)
  }

  alerteDeletion = (ville, renta) => {
    if (this.state.liste_alertes.length === 1) {
      this.props.firebase.deleteLastAlert(this.state.uid, ville, renta)
      this.uploadAlerts()
    } else {
      this.props.firebase.deleteAlert(this.state.uid, ville, renta)
      this.uploadAlerts()
    }
  }

  uploadAlerts(){
    // Cette fonction charge les alertes à partir de la BDD (au démarrage ou après un changement)
    const here = this
    this.props.firebase.getUid(function(uid){
      here.onUidRetrieved(uid)
      common_functions.updateLogs(here.props.firebase, uid, {
        action: 'loadAlerts'
      })
      here.props.firebase.userData(uid)
      .then(doc => {
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          if (doc.data().hasOwnProperty('alertes')){
            here.props.onAlertsRetrieved(doc.data().alertes, doc.data().alerte)
            here.setState(state => {
              const liste_alertes = doc.data().alertes;
              return {liste_alertes}
            });
          }
        }
      })
      .catch(err => {
        console.log('Error getting document', err);
      });
    })
  }

  componentWillMount(){
    this.uploadAlerts()
  }

  render(){
    if (this.state.liste_alertes.length > 0 && this.props.active) {
      return  <Card>
                <CardBody>
                  <Table hover responsive>
                    <thead>
                    <tr>
                      <th>Ville</th>
                      <th>Rentabilité</th>
                      <th>-</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.liste_alertes.map((alerte) => {
                      const key = alerte.ville + alerte.renta
                      return <tr key={key}>
                              <td>{alerte.ville}</td>
                              <td>{alerte.renta}</td>
                              <td>
                                <Button onClick={() => this.alerteDeletion(alerte.ville, alerte.renta)}>Supprimer</Button>
                              </td>
                            </tr>
                    })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>;
    }
    return null
  }
}


const AlertesList = withFirebase(AlertesListBase);
export default AlertesList;
