import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Spinner,
  Table
} from 'reactstrap';

import quick_functions from '../Home/functions.js';
import common_functions from '../Common/functions.js';

import Details from '../Home/Details';
import FieldManager from '../Common/FieldManager';

const INITIAL_STATE = {
  selectedAnnonce: null,
  liste_favoris: [],
  userData: {},
  loading: false
};


class FavorisListBase extends Component {
  constructor() {
    super();

    this.state = { ...INITIAL_STATE };
  }

  componentWillMount(){
    this.setState({loading: true})
    const here = this
    quick_functions.prepareData(this.props.firebase, function(liste_villes, userData){
      common_functions.updateLogs(here.props.firebase, userData.uid, {
        action: 'favoris'
      })
      here.setState({userData: userData, liste_favoris: userData.liked, loading: false})
    })
  }

  onLineSelected(annonce){
    if (this.state.selectedAnnonce !== annonce.id) {
      this.setState({selectedAnnonce: annonce.id})
    } else {
      this.setState({selectedAnnonce: null})
    }
  }

  favDeletion(data){
    //Supprimer
    const liste_favoris = this.state.liste_favoris.filter(item => item.id !== data.id)
    this.setState({liste_favoris: liste_favoris});
    this.props.firebase.deleteLiked(data, liste_favoris)
  }


  render(){
    if (this.state.liste_favoris.length > 0) {
      return  <Card>
                <CardBody>
                  <Table hover responsive>
                    <thead align="center">
                    <tr align="center">
                      <th>Ville</th>
                      <th>Prix</th>
                      <th>Surface</th>
                      <th>Prix/m²</th>
                      <th>Loyer estimé</th>
                      <th>Rentabilité</th>
                      <th>Lien</th>
                      <th>-</th>
                    </tr>
                    </thead>

                    {this.state.liste_favoris.map((annonce) => {
                      return <tbody align="center" key={'tbody'+ annonce.id}>
                              <tr align="center" key={annonce.id} onClick={() => this.onLineSelected(annonce)} >
                                <td key={'ville_'+ annonce.id}>{annonce.ville}</td>
                                <td key={'prix_'+ annonce.id}><FieldManager value={{'prix': annonce.prix}}/></td>
                                <td key={'surface'+ annonce.id}><FieldManager value={{'surface': annonce.surface}}/></td>
                                <td key={'sqm'+ annonce.id}><FieldManager value={{'square_meter_price': Math.round(annonce.square_meter_price)}}/></td>
                                <td key={'loyer'+ annonce.id}><FieldManager value={{'loyer': Math.round(annonce.loyer/10)*10}} /></td>
                                <td key={'renta'+ annonce.id}><FieldManager value={{'renta': Math.round(annonce.renta*10)/10}} /></td>
                                {!annonce.hasOwnProperty('expired')
                                  ? <td key={'lien'+ annonce.id}>
                                      <Button onClick={() => window.open(annonce.url, "_blank")} color="link">Accès à l'annonce</Button>
                                    </td>
                                  : <td>
                                      <Badge className="mr-1" color="dark">Annonce expirée</Badge>
                                    </td>
                                }
                                <td>
                                  <Button onClick={() => this.favDeletion(annonce)}>Supprimer</Button>
                                </td>
                              </tr>
                              <Details colSpanProp={"7"} selectedAnnonce={this.state.selectedAnnonce} annonce={annonce} isClicked={null} />
                            </tbody>
                    })}
                  </Table>
                </CardBody>
              </Card>;
    }
    if (this.state.loading){
      return <Spinner color="success" style={{position: "fixed", top: "50%", left: "50%"}}/>
    }
    return <div style={{position: "fixed", top: "20%", left: "33%", textAlign: "center"}}>
            <h2>
              Vous n'avez aucun favori
            </h2>
            <p>
              Vous pouvez ajouter des favoris en cliquant sur l'étoile quand vous recherchez un bien.
              Ils apparaîtront ici
            </p>
          </div>
  }
}


const Favoris = withFirebase(FavorisListBase);
export default Favoris;
