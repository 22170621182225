import React from 'react';
import {
  CardHeader,
  CardTitle,
  CardText,
  UncontrolledCollapse,
  CardBody,
  Card
} from 'reactstrap';
import { Link } from 'react-router-dom';

const Faq = (props) => {
  return (
    <div style={{
      width: "60%",
      paddingTop: "20px",
      marginLeft: "auto",
      marginRight: "auto",
    }}>
      <Card>
        <CardHeader id="first">
          <CardTitle>Comment ça marche?</CardTitle>
        </CardHeader>
        <UncontrolledCollapse toggler="#first">
          <CardBody>
            <CardText>
              <p>
                <i>RentaFirst</i> est un site web mettant à disposition des investisseurs des outils pour <b>simplifier et accélérer la recherche d'un bien rentable</b>.
              </p>
              <p>
              <i>RentaFirst</i> est avant tout un comparateur d'annonces immobilères: L'outil phare permet de classer les annonces immobilières de différents sites selon leur rentabilité ou selon leur prix au mètre carré (pour une ville donnée). {<br/>}
              Il est également possible de recevoir quotidiennement un email qui recensera toutes les annonces parues la veille dont la rentabilité sera supérieure au seuil que vous aurez fixé.
              Pour en profiter, il suffit de s'inscrire en cliquant <Link to={'/signup'}>ici</Link>.
              </p>
              <p>
              Après plus d'un an de travail acharné, nous avons développé plusieurs algorithmes d'<i>Intelligence Artificielle</i> (<a href={'https://fr.wikipedia.org/wiki/Apprentissage_automatique'} >Machine Learning</a> & <a href={'https://fr.wikipedia.org/wiki/Traitement_automatique_du_langage_naturel'} >Natural Language Processing</a>)
              qui permettent d'identifier les meilleures opportunités d'investissement immobilier et les mettre à votre disposition en quelques clics. De plus, nos outils permettent de faire ressortir les schémas les plus rentables par ville (exemple: T2 compris entre 30 et 40m2).
              À noter que, pour l'heure, nous nous concentrons sur l'investissement locatif.
              </p>
              <p>
              Nous avons pour ambition en 2020 de permettre également à nos utilisateurs d'identifier les meilleures opportunités pour:
              <li>La location courte durée,</li>
              <li>Les immeubles de rapport, </li>
              <li>L'achat revente <i>(déjà partiellement possible grâce au filtre prix/m²) </i></li>
              </p>
              <p>
                <i>Pensez à nous <a href={'https://www.instagram.com/rentafirst'} >suivre sur Instagram</a> pour être informé des évolutions de notre plateforme</i>
              </p>
            </CardText>
          </CardBody>
        </UncontrolledCollapse>
      </Card>
      <Card>
        <CardHeader id="second">
          <CardTitle>À qui s'adresse RentaFirst?</CardTitle>
        </CardHeader>
        <UncontrolledCollapse toggler="#second">
          <CardBody>
            <CardText>
            <p>
              <i>RentaFirst</i> s'adresse aux personnes <b>en recherche active d'un bien immobilier</b>.
            </p>
            <p>
              Par ailleurs, un minimum de connaissances est requis pour faire un bon usage de nos outils.
              En effet, même si nos outils mettent à disposition les annonces les plus rentables, elles sont aussi souvent les plus risquées.
              Charge à l'investisseur de s'assurer de la demande locative, de la solvabilité des potentiels locataires etc.
            </p>
            <p>
              <i>Si vous n'avez pas de connaissances en investissement immobilier, nous recommandons vivement de vous former avant d'utiliser nos outils </i>
            </p>
            </CardText>
          </CardBody>
        </UncontrolledCollapse>
      </Card>
      <Card>
        <CardHeader id="third">
          <CardTitle>Pourquoi RentaFirst est gratuit?</CardTitle>
        </CardHeader>
        <UncontrolledCollapse toggler="#third">
          <CardBody>
            <CardText>
            <p>
              Pour l'heure, <i>RentaFirst</i> est en phase de test.
            </p>
            <p>
              Nous souhaitons nous rapprocher le plus possible des attentes de nos utilisateurs.
              De plus, de nombreuses fonctionnalités sont encore en cours de développement.
            </p>
            <p>
              En revanche, <i>RentaFirst</i> est <b>voué à devenir payant. </b>
            </p>
            </CardText>
          </CardBody>
        </UncontrolledCollapse>
      </Card>
      <Card>
        <CardHeader id="fourth">
          <CardTitle>Comment est calculée la rentabilité?</CardTitle>
        </CardHeader>
        <UncontrolledCollapse toggler="#fourth">
          <CardBody>
            <CardText>
              <p>
              Le calcul de la rentabilité fut l'un de nos plus grands challenges.
              </p>
              <p>
              Pour ce faire, nous avons élaboré un algorithme de Machine Learning adapté à l'immobilier.
              Cet algorithme est entrainé avec des milliers de données.
              Et, pour chaque annonce, l'algorithme utilise plus d'une dizaine de critères pour en estimer le loyer.
              </p>
              <p>
                Attention, il est important de bien retenir les points suivants:
                <li>Les chiffres fournis par notre algorithme sont indicatifs et doivent être vérifiés. En aucun cas, nos chiffres ne peuvent prévaloir ou se substituer aux conseils d'un professionnel (agent immobilier du secteur). </li>
                <li>Nos outils ont de la valeur parce qu'ils permettent de comparer les annonces les unes aux autres</li>
              </p>
            </CardText>
          </CardBody>
        </UncontrolledCollapse>
      </Card>
      <Card>
        <CardHeader id="fifth">
          <CardTitle>Quelles sont les villes disponibles? Pourquoi n'y a-t-il pas Paris?</CardTitle>
        </CardHeader>
        <UncontrolledCollapse toggler="#fifth">
          <CardBody>
            <CardText>
            <p>
            Pour l'heure, près de 20 grandes villes sont disponibles.
            Nous visons une centaine de villes disponibles pour 2020.
            </p>
            <p>
            Nous limitons volontairement les villes disponibles en fonction de la précision de nos estimations.
            Pour l'instant, seules les villes pour lesquelles nos algorithmes sont suffisament précis sont mis à disposition.
            </p>
            <p>
            Paris ne figure pas parmi les villes que nous voulons mettre à disposition car:
            <li>Cela demanderait beaucoup de ressources et que nos moyens ne nous le permettent pas encore</li>
            <li>Nous pensons que nos solutions s'adressent à des investisseurs aguéris et donc désireux de générer un cashflow (revenu net) de leurs investissements locatifs</li>
            </p>
            </CardText>
          </CardBody>
        </UncontrolledCollapse>
      </Card>
    </div>
  );
}

export default Faq;
