import React from 'react';
import './App.css';
import './App.scss';

import { BrowserRouter as Router, Route } from 'react-router-dom';

import Navigation from './components/Navigation';
import LandingPage from './components/Landing';
import Navbar from './components/Landing/composants/Navbar';
import SignUpPage from './components/SignUp';
import SignInPage from './components/SignIn';
import PasswordForgetPage from './components/PasswordForget';
import HomePage from './components/Home';
import AccountPage from './components/Account';
import AdminPage from './components/Admin';
import Search from './components/Search';
import Alerts from './components/Alerts';
import Favoris from './components/Favoris';
import FAQ from './components/Static/FAQ.js';
import Hashtags from './components/Static/hashtags.js';
import MentionsLegales from './components/Static/mentions.js';


import * as ROUTES from './constants/routes';
import { withAuthentication } from './components/Session';


const App = () => (
  <Router forceRefresh={true}>
    <div>

      <Route render={({ location }) => {
        return location.pathname !== "/" ? <Navigation /> : <Navbar />
      }} />
      <Route exact path={ROUTES.LANDING} component={LandingPage} />

      <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
      <Route path={ROUTES.HOME} component={HomePage} />
      <Route path={ROUTES.SEARCH} component={Search} />
      <Route path={ROUTES.ALERTS} component={Alerts} />
      <Route path={ROUTES.ACCOUNT} component={AccountPage} />
      <Route path={ROUTES.FAVORIS} component={Favoris} />
      <Route path={ROUTES.FAQ} component={FAQ} />
      <Route path={ROUTES.MENTIONS} component={MentionsLegales} />
      <Route path={ROUTES.HASHTAGS} component={Hashtags} />
      <Route path={ROUTES.ADMIN} component={AdminPage} />
    </div>
  </Router>
);

export default withAuthentication(App);
