import React, { Component } from 'react';
import {
  Nav,
  NavbarBrand,
  NavItem,
  NavLink
 } from 'reactstrap';

import { NavLink as RRNavLink } from 'react-router-dom';

import styles from './Landing.module.css';
import logo from './Logo2.png';
import * as ROUTES from '../../constants/routes';

import { AuthUserContext } from '../Session';

class Navigation extends Component {

  render() {
    return (
      <div>
          <AuthUserContext.Consumer>
            {authUser =>
              authUser ? <NavigationAuth /> : <NavigationNonAuth />
            }
          </AuthUserContext.Consumer>
      </div>
  )}
};



const NavigationAuth = () => (
  <Nav tabs>
    <NavbarBrand href="/" className="navbarBrand mr-auto">
      <img className={styles.logo} src={logo} alt="RentaFirst"/>
    </NavbarBrand>
    <NavItem className="ml-auto">
      <NavLink to={ROUTES.HOME} style={{color:'black'}} activeClassName="active" tag={RRNavLink}>Trouver une affaire</NavLink>
    </NavItem>
    <NavItem>
      <NavLink to={ROUTES.ALERTS} style={{color:'black'}} activeClassName="active" tag={RRNavLink}>Alertes</NavLink>
    </NavItem>
    <NavItem>
      <NavLink to={ROUTES.FAVORIS} style={{color:'black'}} activeClassName="active" tag={RRNavLink}>Favoris</NavLink>
    </NavItem>
    <NavItem>
      <NavLink to={ROUTES.ACCOUNT} style={{color:'black'}} activeClassName="active" tag={RRNavLink}>Compte</NavLink>
    </NavItem>
  </Nav>
)



const NavigationNonAuth = () => (
  <Nav tabs>
    <NavbarBrand href="/" className="navbarBrand mr-auto">
      <img className={styles.logo} src={logo} alt="RentaFirst"/>
    </NavbarBrand>
    <NavItem>
      <NavLink exact to={ROUTES.LANDING} activeClassName="active" tag={RRNavLink}>Accueil</NavLink>
    </NavItem>
    <NavItem>
      <NavLink to={ROUTES.SIGN_IN} activeClassName="active" tag={RRNavLink}>Connexion</NavLink>
    </NavItem>
  </Nav>
);

export default Navigation;
