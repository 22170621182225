import React from 'react';
import {
  Badge
} from 'reactstrap';


function FieldManager(props){
  //Permet de mettre la bonne unité (€...)
  if (props.value.hasOwnProperty('renta')) {
    if (isNaN(props.value.renta)) {
      return <Badge className="mr-1" color="light">Indisponible</Badge>
    } else {
      return props.value.renta + '%'
    }
  } else if (props.value.hasOwnProperty('prix') || props.value.hasOwnProperty('loyer') || props.value.hasOwnProperty('square_meter_price')){
    if (props.value.hasOwnProperty('loyer') && isNaN(props.value.loyer)) {
      return <Badge className="mr-1" color="light">Indisponible</Badge>
    } else {
      return props.value[Object.keys(props.value)[0]] + '€'
    }
  } else if (props.value.hasOwnProperty('surface')){
    return props.value[Object.keys(props.value)[0]] + 'm²'
  } else {
    return props.value
  }
}

export default FieldManager;
