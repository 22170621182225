import React from 'react';

import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';
import { AuthUserContext, withAuthorization } from '../Session';
import SignOutButton from '../SignOut';

const AccountPage = () => (
  <div>
    <AuthUserContext.Consumer>
      {authUser => (
        <div>
          <h2>Compte: {authUser.email}</h2>
          <PasswordForgetForm />
          <PasswordChangeForm />
        </div>
      )}
    </AuthUserContext.Consumer>
    <SignOutButton/>
  </div>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AccountPage);
