import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';

class Pricing extends React.Component {
  render() {
  	return (
        <section className="section pt-5" id="pricing">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <h1 className="section-title text-center">Tarifs</h1>
                    <div className="section-title-border margin-t-20"></div>
                    <p className="section-subtitle font-secondary text-muted text-center padding-t-30">
                      À partir de Mars 2020, nous proposerons <b>plusieurs options</b> en fonction du <b>profil d'investisseur</b>. {<br/>}
                      Les prélèvements ne seront <b>pas automatisés par défaut</b> pour éviter les mauvaises surprises.
                    </p>
                </div>
            </div>
            <div className="row margin-t-50">
                <div className="col-lg-4">
                    <div className="text-center pricing-box hover-effect">
                        <h4 className="text-uppercase">Débutant</h4>
                        <div style={{fontSize:"180%"}}><b className="text-custom">GRATUIT</b></div>
                        <div style={{fontSize:"130%", color: "#5D5D5D"}}><b> jusqu'en mars 2020 </b></div>
                        <div className="pricing-border"></div>
                        <strike><h1>99€</h1>
                        <h4> PAR MOIS </h4>
                        <h6 className="text-uppercase text-muted">pour un mois</h6></strike>
                        <Link to={ROUTES.HOME} style={{zIndex: 998, color: 'white'}} className="btn btn-custom waves-effect waves-light margin-t-30">C'est parti!</Link>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="text-center pricing-box bg-white hover-effect price-active">
                        <h4 className="text-uppercase">Futur rentier</h4>
                        <div style={{fontSize:"180%"}}><b className="text-custom">GRATUIT</b></div>
                        <div style={{fontSize:"130%", color: "#5D5D5D"}}><b> jusqu'en mars 2020 </b></div>
                        <div className="pricing-border"></div>
                        <strike><h1>79€</h1>
                        <h4> PAR MOIS </h4>
                        <h6 className="text-uppercase text-muted">pour 3 mois</h6></strike>
                        <Link to={ROUTES.HOME} style={{zIndex: 998, color: 'white'}} className="btn btn-custom waves-effect waves-light margin-t-30">J'en profite!</Link>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="text-center pricing-box hover-effect">
                        <h4 className="text-uppercase">Chasseur d'apparts</h4>
                        <div style={{fontSize:"180%"}}><b className="text-custom">GRATUIT</b></div>
                        <div style={{fontSize:"130%", color: "#5D5D5D"}}><b> jusqu'en mars 2020 </b></div>
                        <div className="pricing-border"></div>
                        <strike><h1>49€</h1>
                        <h4> PAR MOIS </h4>
                        <h6 className="text-uppercase text-muted">Pour un an</h6></strike>
                        <Link to={ROUTES.HOME} style={{zIndex: 998, color: 'white'}} className="btn btn-custom waves-effect waves-light margin-t-30">Allons-y!</Link>
                    </div>
                </div>
            </div>
        </div>
    </section>
  	);
  }
}
export default Pricing;
